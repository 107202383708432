import React, { useEffect, useState } from "react";
import Menu from "../../Components/Menu";
import { useAuthState } from "../../Context";
import styles from "./facturas.module.css";
import {
  InputGroup,
  FormControl,
  Button,
  FormLabel,
  Form,
  Row,
  Col,
  Accordion,
  Spinner,
} from "react-bootstrap";
import { format } from "date-fns";
import ReactExport from "react-export-excel";
import FacturasTable from "../../Components/FacturasTable";
import axios from "axios";
import { useQuery } from "react-query";
import LoadingSpinner from "../../Components/LoadingSpinner";
import FacturaModal from "../../Components/FacturaModal";
import useDebounce from "../../hooks/useDebounce";
import useSearchParams from "../../hooks/useSearchParams";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const PER_PAGE = 50;

function Facturas() {
  const [currentPage, setCurrentPage] = useState(0);
  const [sorting, setSorting] = useState("createdAt");
  const debouncedSorting = useDebounce(sorting, 200)
  const [sortWay, setSortWay] = useState("DESC");
  const debouncedSortWay = useDebounce(sortWay, 200)

  const userDetails = useAuthState();
  const [facturaIndex, setFacturaIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [smurking, setSmurking] = useState(false)

  const [facturasToExport, setFacturasToExport] = useState([])
  const [excelStatus, setExcelStatus] = useState("idle")

  const { pushSearchParam, getParam } = useSearchParams()

  const hasta = getParam("hasta")
  const desde = getParam("desde")
  const serie = getParam("serie")
  const formadepago = getParam("formadepago")
  const vendedor = getParam("vendedor")
  const contabilizadas = getParam("contabilizadas")

  const debouncedSerie = useDebounce(serie, 800)
  const debouncedHasta = useDebounce(hasta, 1000)
  const debouncedDesde = useDebounce(desde, 1000)

  const fetchFacturas = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/facturas?_sort=${debouncedSorting}:${debouncedSortWay}&_start=${currentPage * PER_PAGE}&_limit=${PER_PAGE + 1}${debouncedDesde ? `&createdAt_gte=${debouncedDesde}` : ""}${debouncedHasta ? `&createdAt_lte=${debouncedHasta}` : ""}${formadepago ? `&formadepago=${formadepago}` : ""}${userDetails.user.role.type !== "authenticated" ? `&user=${userDetails.user.id}` : vendedor ? `&user=${vendedor}` : ""}${debouncedSerie ? `&serie_contains=${debouncedSerie}` : ""}${contabilizadas ? `&contabilizada=${contabilizadas}` : ""}`;
    console.log("fetch facturas", url)
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    console.log("facc", data)
    return data
  }



  const fetchFacturasToExport = async () => {
    const batch = 1000
    let page = 0
    setExcelStatus("loading")
    let data = await recursiveFetch(getUrl(batch, page))
    setFacturasToExport(data)
    while (data.length === batch) {
      page = page + 1
      data = await recursiveFetch(getUrl(batch, page))
      setFacturasToExport(list => ([...list, ...data]))
    }
    setExcelStatus("success")
  };

  const getUrl = (batch, page) => {
    return `${process.env.REACT_APP_BACKEND_URL}/facturas?_sort=${debouncedSorting}:${debouncedSortWay}&_limit=${batch}&_start=${page === 0 ? 0 : page * batch + 1}${debouncedDesde ? `&createdAt_gte=${debouncedDesde}` : ""}${debouncedHasta ? `&createdAt_lte=${debouncedHasta}` : ""}${formadepago ? `&formadepago=${formadepago}` : ""}${vendedor ? `&user=${vendedor}` : ""}${debouncedSerie ? `&serie_contains=${debouncedSerie}` : ""}${contabilizadas ? `&contabilizada=${contabilizadas}` : ""}`;
  }

  const recursiveFetch = async (url) => {
    console.log("recursive fetch", url)
    const { data } = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    console.log("data", data)
    return data
  }

  useEffect(() => {
    setExcelStatus("idle")
  }, [formadepago, debouncedDesde, debouncedHasta, vendedor, debouncedSerie, contabilizadas])


  const facturas = useQuery(['facturas', formadepago, debouncedDesde, debouncedHasta, vendedor, debouncedSorting, debouncedSortWay, currentPage, debouncedSerie, contabilizadas], fetchFacturas)
  const users = useQuery('users', async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/users`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    return data
  }, { enabled: userDetails.user.role.type === "authenticated" })
  const formasdepago = useQuery('formasdepago', async () => {
    const { data } = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/formadepagos`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userDetails.token}`,
      },
    })
    return data
  })


  useEffect(() => {
    setCurrentPage(0)
  }, [formadepago, debouncedDesde, debouncedHasta, vendedor, debouncedSerie, contabilizadas])


  const toggleSortWay = () => {
    setSortWay(value => value === "DESC" ? "ASC" : "DESC")
  }

  const sortBy = (newSorting) => {
    if (sorting === newSorting) toggleSortWay()
    else setSorting(newSorting)
  }

  const doHideModal = () => {
    setFacturaIndex(0)
    setShowModal(false)
  }
  const doShowModal = (index) => {
    console.log("SHOW MODAL")
    setFacturaIndex(index)
    setShowModal(true)
  }

  useEffect(() => {
    console.log("FACTURAS TO EXPORT STATUS", excelStatus)
  }, [excelStatus])

  return (
    <>
      {facturas.isSuccess && <FacturaModal
        factura={facturas.data[facturaIndex]}
        showModal={showModal}
        doHideModal={doHideModal} />}
      <Menu>
        <div className="container">
          <h1>Facturas</h1>
          <br />
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Filtros</Accordion.Header>
              <Accordion.Body>
                <div className={styles.filters}>
                  <Row>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="compra-desde-control">
                          A partir de:
                        </FormLabel>

                        <FormControl
                          type="date"
                          id="compra-desde-control"
                          placeholder="yyyy-mm-dd"
                          value={desde || ""}
                          name="desde"
                          onChange={pushSearchParam}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="compra-hasta-control">
                          Antes de:
                        </FormLabel>

                        <FormControl
                          type="date"
                          id="compra-hasta-control"
                          placeholder="yyyy-mm-dd"
                          value={hasta || ""}
                          name="hasta"
                          onChange={pushSearchParam}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="serie-control">
                          Serie:
                        </FormLabel>

                        <FormControl
                          type="text"
                          id="serie-control"
                          value={serie || ""}
                          name="serie"
                          onChange={pushSearchParam}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>

                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="status-select">
                          Forma de pago:
                        </FormLabel>
                        <Form.Select
                          id="status-select"
                          name="formadepago"
                          onChange={pushSearchParam}
                          aria-label="Forma de pago"
                        >
                          <option value="">Todos</option>
                          {formasdepago.isSuccess && formasdepago.data.map((formadepago, index) => {
                            return <option value={formadepago.id} key={index}>{formadepago.formadepago}</option>
                          })}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="vendedor-select">
                          Vendedor:
                        </FormLabel>
                        <Form.Select
                          id="vendedor-select"
                          name="vendedor"
                          onChange={pushSearchParam}
                          aria-label="Vendedor select"
                          disabled={userDetails.user.role.type !== "authenticated"}
                        >
                          {userDetails.user.role.type !== "authenticated" ?
                            <>
                              <option value={userDetails.user.id}>{userDetails.user.username}</option>
                            </>
                            :
                            <>
                              <option value="">Todos</option>
                              {users.isSuccess && users.data.map(user => {
                                return <option value={user.id} key={user.id}>{user.username}</option>
                              })}
                            </>}
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <InputGroup className="m-3">
                        <FormLabel className="m-2" htmlFor="contabilizadas_checkbox">
                          Contabilizadas:
                        </FormLabel>
                        <Form.Select
                          id="contabilizadas_checkbox"
                          name="contabilizadas"
                          onChange={pushSearchParam}
                          disabled={userDetails.user.role.type !== "authenticated"}
                        >
                          <option value="">Todas</option>
                          <option value="true">Contabilizadas</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2} sm={12} className="p-">
                      {excelStatus === "success" &&
                        <ExcelFile
                          /**</Col>element={
                            <Button
                              variant="primary"
                              className="m-3"
                              disabled={excelStatus !== "success"}
                            >
                              Descargar Excel
                            </Button>
                          }*/
                          hideElement={true}
                        >
                          <ExcelSheet data={facturasToExport} name="Facturas">
                            <ExcelColumn label="Localizador" value={(factura) =>
                              factura.booking?.localizador || ""
                            } />
                            <ExcelColumn label="Serie" value="serie" />
                            <ExcelColumn label="Numeración" value="numero" />
                            <ExcelColumn label="Importe" value="importe" />
                            <ExcelColumn
                              label="Fecha de emisión"
                              value={(factura) =>
                                format(new Date(factura.createdAt), "dd-MM-yyyy")
                              }
                            />
                            <ExcelColumn
                              label="Hora de emisión"
                              value={(factura) =>
                                factura.serie === "BAS" ? "" :
                                  format(new Date(factura.createdAt), "HH:mm")
                              }
                            />
                            <ExcelColumn
                              label="Rectificada por"
                              value={(factura) =>
                                factura.rectificada ? `${factura.rectificada.serie}${factura.rectificada.numero}` : ""
                              }
                            />
                            <ExcelColumn
                              label="Rectifica"
                              value={(factura) =>
                                factura.rectifica ? `${factura.rectifica.serie}${factura.rectifica.numero}` : ""
                              }
                            />
                            <ExcelColumn label="Forma de pago" value={factura => factura.formadepago.formadepago} />
                            <ExcelColumn
                              label="Trayecto"
                              value={(factura) =>
                                factura.booking ?
                                  `${factura.booking?.is_bono ? "BONO" : factura.booking?.ida ? `${factura.booking.ida.details.origin.code} - ${factura.booking.ida.details.destination.code}` : ""}` : factura.serie === "BAS" ? "VENTA BAR" : ""
                              }
                            />
                            <ExcelColumn
                              label="Ida y vuelta"
                              value={(factura) => (factura.booking ? factura.booking.idayvuelta === "si" ? "Solo ida" : factura.booking.idayvuelta === "iyv" ? "Ida y vuelta" : "Bono" : "")}
                            />
                            <ExcelColumn
                              label="Adultos"
                              value={(factura) => factura.booking ? factura.booking.adults : ""}
                            />
                            <ExcelColumn
                              label="Niños"
                              value={(factura) => factura.booking ? factura.booking.children : ""}
                            />
                            <ExcelColumn
                              label="residente"
                              value={(factura) => (factura.booking ? factura.booking.residente ? "Residente" : "" : "")}
                            />
                            <ExcelColumn
                              label="email"
                              value={(factura) => factura.booking ? factura.booking.email : ""}
                            />
                            <ExcelColumn
                              label="Razón Fiscal"
                              value={(factura) =>
                                factura.datosfacturacion &&
                                factura.datosfacturacion.razonFiscal &&
                                factura.datosfacturacion.razonFiscal
                              }
                            />
                            <ExcelColumn
                              label="cif"
                              value={(factura) =>
                                factura.datosfacturacion &&
                                factura.datosfacturacion.cif &&
                                factura.datosfacturacion.cif
                              }
                            />
                            <ExcelColumn
                              label="direccion"
                              value={(factura) =>
                                factura.datosfacturacion &&
                                factura.datosfacturacion.direccion &&
                                factura.datosfacturacion.direccion
                              }
                            />
                            <ExcelColumn
                              label="poblacion"
                              value={(factura) =>
                                factura.datosfacturacion &&
                                factura.datosfacturacion.poblacion &&
                                factura.datosfacturacion.poblacion
                              }
                            />
                            <ExcelColumn
                              label="cp"
                              value={(factura) =>
                                factura.datosfacturacion &&
                                factura.datosfacturacion.cp &&
                                factura.datosfacturacion.cp
                              }
                            />
                            <ExcelColumn
                              label="provincia"
                              value={(factura) =>
                                factura.datosfacturacion &&
                                factura.datosfacturacion.provincia &&
                                factura.datosfacturacion.provincia
                              }
                            />
                            <ExcelColumn
                              label="pais"
                              value={(factura) =>
                                factura.datosfacturacion &&
                                factura.datosfacturacion.pais &&
                                factura.datosfacturacion.pais
                              }
                            />
                            <ExcelColumn
                              label="conceptos"
                              value={(factura) =>
                                factura.serie === "BAS" ? JSON.stringify(factura.conceptos) : ""
                              }
                            />
                          </ExcelSheet>
                        </ExcelFile>
                      }
                      <Button
                        variant="primary"
                        className="m-3"
                        disabled={excelStatus === "loading" || userDetails.user.role.type !== "authenticated"}
                        onClick={() => fetchFacturasToExport()}>
                        {excelStatus === "loading" ? <><Spinner animation="border" size="sm" /> Exportando...</> : "Exportar a Excel"}
                      </Button>
                    </Col>
                    {userDetails.user.username === "toni" &&
                      <Col md={2} sm={12}>
                        <Button
                          className="m-3" variant="warning" disabled={smurking} onClick={async () => {
                            setSmurking(true)
                            try {
                              await fetch(`${process.env.REACT_APP_BACKEND_URL}/snackmock`, {
                                method: "POST",
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${userDetails.token}`,
                                },
                                body: JSON.stringify({
                                  user: vendedor
                                })
                              });
                            } catch {
                              console.log("smurk went wrong")
                            } finally {
                              setSmurking(false)
                            }
                          }}>{smurking ? <Spinner animation="border" size="sm" /> : "Smurk"}</Button>
                      </Col>
                    }
                  </Row>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <br />
          {facturas.isLoading && <LoadingSpinner />}
          {facturas.isSuccess && <FacturasTable facturas={facturas.data} sorting={sorting} sortWay={sortWay} sortBy={sortBy} currentPage={currentPage} setCurrentPage={setCurrentPage} doShowModal={doShowModal} PER_PAGE={PER_PAGE} />}
        </div>
      </Menu >
    </>
  );
}

export default Facturas;
